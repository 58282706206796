import { useRef, useState } from 'react';
import {
  CHECKOUT_SUCCESS_STATUS,
  CHECKOUT_PAYMENT_FAILURE_STATUS,
  CHECKOUT_PROCESSING_STATUS,
  CHECKOUT_UNKNOWN_FAILURE_STATUS,
  CHECKOUT_POST_PAYMENT_FAILURE_STATUS
} from '@iamilyas/store-template-library';
import { alpha, styled } from '@material-ui/core/styles';
import { Stack, Button, Typography, Menu, MenuItem, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import cogsIcon from '@iconify/icons-mdi/cogs';
import outlineLocalShipping from '@iconify/icons-ic/outline-local-shipping';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import baselineError from '@iconify/icons-ic/baseline-error';
import hourglassOutline from '@iconify/icons-ion/hourglass-outline';

const RootStyle = styled(Stack)(({ theme }) => ({
  border: `solid 1px ${alpha(theme.palette.primary.main, 0.24)}`,
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(2)
}));

const ChooseButton = styled(Button)(() => ({
  width: '100%',
  height: 35
}));

export const CHECKOUT_STAGE_OPTIONS = [
  {
    name: 'In Progress',
    value: 'IN_PROGRESS',
    icon: dotsHorizontal,
    description: 'Customer is progressing through the checkout journey to complete their order.'
  },
  {
    name: 'Loading',
    value: 'LOADING',
    icon: hourglassOutline,
    description:
      'Payment is completed and a customer is waiting to be presented with their next step. This can be either a success or failure.'
  },
  {
    name: 'Payment Success',
    value: CHECKOUT_SUCCESS_STATUS,
    icon: checkmarkCircle2Fill,
    description: 'Everything is successful. You can begin fulfilling the order.'
  },
  {
    name: 'Payment Processing',
    value: CHECKOUT_PROCESSING_STATUS,
    icon: cogsIcon,
    description: 'The chosen payment provider is processing the payment.'
  },
  {
    name: 'Payment Failure',
    value: CHECKOUT_PAYMENT_FAILURE_STATUS,
    icon: baselineError,
    description: 'Customer payment has failed. For example, insufficient funds or bank payment verification.'
  },
  {
    name: 'Post Payment Failure',
    value: CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
    icon: outlineLocalShipping,
    description: 'Customer payment was successful but a problem occurred completing the order.'
  },
  {
    name: 'General Failure',
    value: CHECKOUT_UNKNOWN_FAILURE_STATUS,
    icon: baselineError,
    description: 'An uncommon scenario. All other errors will display this page.'
  }
];

export const CheckoutStageSelector = ({ onSelectOption, sx }) => {
  const [value, setValue] = useState(CHECKOUT_STAGE_OPTIONS[0]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <RootStyle direction="column" spacing={2} {...sx}>
      <Stack spacing={1}>
        <Typography variant="subtitle2" color="secondary.contrastText">
          Checkout Preview
        </Typography>
        <Box sx={{ display: 'flex', color: 'secondary.contrastText' }}>
          <Icon icon={value.icon} width={20} height={20} sx={{ alignSelf: 'center', color: 'inherit' }} />
          <Typography variant="subtitle2" sx={{ marginLeft: 1, color: 'secondary.contrastText' }}>
            {value.name}
          </Typography>
        </Box>
        <Typography variant="caption" sx={{ color: 'secondary.contrastText' }}>
          {value.description}
        </Typography>
      </Stack>
      <ChooseButton
        ref={anchorRef}
        fullWidth
        variant="outlined"
        size="small"
        onClick={handleOpen}
        sx={{ position: 'relative' }}
      >
        Choose Checkout Journey
      </ChooseButton>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { mt: 2, width: 230, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {CHECKOUT_STAGE_OPTIONS.map((stage) => {
          const { name, value, icon } = stage;
          return (
            <MenuItem
              key={value}
              onClick={() => {
                setValue(stage);
                handleClose();
                onSelectOption(value);
              }}
            >
              <Icon icon={icon} width={20} height={20} />
              <Typography variant="body2" sx={{ ml: 2 }}>
                {name}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </RootStyle>
  );
};
