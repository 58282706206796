// ----------------------------------------------------------------------

import { PAYMENT_STATUS_KEY } from 'src/pages/admin_v2/PlansPayment';

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  signup: '/signup',
  resetPassword: '/reset-password'
};

export const PATH_PAGE = {
  home: '/',
  maintenance: '/maintenance',
  pricing: '/pricing',
  contactUs: '/contact-us',
  contactSales: '/contact-us/sales',
  contactCommunications: '/contact-us/general',
  faqs: '/faqs',
  general: {
    paymentGateways: '/payment-gateways'
  },
  resources: {
    startOnlineEcommerceBusiness: '/resources/start-your-online-ecommerce-store'
  },
  legal: {
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions'
  },
  info: {
    ecommerce: {
      root: '/ecommerce',
      overview: '/ecommerce/overview',
      currencies: '/ecommerce/currencies'
    }
  },
  support: {
    serviceStatus: '/service-status'
  },
  articles: {
    root: '/articles',
    article: (resource) => `/articles/${resource}`
  },
  help: {
    stripe: { helpConnectAccount: '/help/stripe/how-to-connect-your-stripe-account' },
    klaviyo: { helpConnectAccount: '/help/klaviyo/how-to-connect-your-klaviyo-account' },
    aliExpress: { helpProductId: '/help/ali-express/how-to-import-a-product-from-ali-express' }
  },
  page404: '/404',
  page500: '/500'
};

export const PATH_EXTERNAL = {
  aliExpress: {
    root: 'https://www.aliexpress.com',
    searchPage: 'https://www.aliexpress.com'
  }
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: {
    root: path(ROOTS_DASHBOARD, '/account')
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    order: (id) => path(PATH_DASHBOARD.order.orders, `/${id}`)
  },
  booking: {
    root: path(ROOTS_DASHBOARD, '/bookings'),
    bookings: path(ROOTS_DASHBOARD, '/bookings'),
    booking: (id) => path(ROOTS_DASHBOARD, `/bookings/${id}`),
    bookingDefinitions: path(ROOTS_DASHBOARD, '/booking-definitions'),
    bookingDefinitionCreate: path(ROOTS_DASHBOARD, '/booking-definitions/create'),
    bookingDefinition: (id) => path(ROOTS_DASHBOARD, `/booking-definitions/${id}`),
    bookingLocation: (bookingId, id) => path(ROOTS_DASHBOARD, `/booking-definitions/${bookingId}/locations/${id}`),
    bookingLocationCreate: (bookingId) => path(ROOTS_DASHBOARD, `/booking-definitions/${bookingId}/locations/create`),
    bookingSchedule: (bookingId, locationId) =>
      path(ROOTS_DASHBOARD, `/booking-definitions/${bookingId}/locations/${locationId}/schedules`),
    bookingScheduleCreate: (bookingId, locationId) =>
      path(ROOTS_DASHBOARD, `/booking-definitions/${bookingId}/locations/${locationId}/schedules/create`)
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    overview: path(ROOTS_DASHBOARD, '/reports/overview'),
    report: (resource) => path(ROOTS_DASHBOARD, `/reports/${resource}`)
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    customers: path(ROOTS_DASHBOARD, '/customers'),
    customer: (id) => path(PATH_DASHBOARD.customer.customers, `/${id}`)
  },
  discount: {
    root: path(ROOTS_DASHBOARD, '/discounts'),
    discounts: path(ROOTS_DASHBOARD, '/discounts'),
    create: path(ROOTS_DASHBOARD, '/discounts/create'),
    discount: (code) => path(PATH_DASHBOARD.discount.discounts, `/${code}`),
    edit: path(ROOTS_DASHBOARD, '/discounts/:code')
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/products'),
    create: path(ROOTS_DASHBOARD, '/products/create'),
    product: (id) => path(PATH_DASHBOARD.product.products, `/${id}`),
    products: path(ROOTS_DASHBOARD, '/products'),
    productsImport: path(ROOTS_DASHBOARD, '/products/import'),
    inventory: path(ROOTS_DASHBOARD, '/inventory'),
    groups: path(ROOTS_DASHBOARD, '/collections'),
    groupCreate: path(ROOTS_DASHBOARD, '/collections/create'),
    group: (id) => path(PATH_DASHBOARD.product.groups, `/${id}`)
  },
  plans: {
    root: path(ROOTS_DASHBOARD, '/plans'),
    changePlan: (id) => path(PATH_DASHBOARD.plans.root, `/${id}/change-plan`),
    payment: (id) => path(PATH_DASHBOARD.plans.root, `/${id}/payment`),
    paymentRedirectUrl: (status) => {
      const url = new URL(window.location.href);
      url.searchParams.append(PAYMENT_STATUS_KEY, status);
      return url.toString();
    },
    onboarding: path(ROOTS_DASHBOARD, '/plans/get-started')
  },
  messages: {
    root: path(ROOTS_DASHBOARD, '/messages')
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/settings/general'),
    general: path(ROOTS_DASHBOARD, '/settings/general'),
    shipping: path(ROOTS_DASHBOARD, '/settings/shipping'),
    files: path(ROOTS_DASHBOARD, '/settings/files'),
    notifications: path(ROOTS_DASHBOARD, '/settings/notifications'),
    users: path(ROOTS_DASHBOARD, '/settings/users'),
    payments: path(ROOTS_DASHBOARD, '/settings/payments'),
    messaging: path(ROOTS_DASHBOARD, '/settings/messaging'),
    plan: path(ROOTS_DASHBOARD, '/settings/plan'),
    billing: path(ROOTS_DASHBOARD, '/settings/billing'),
    statements: path(ROOTS_DASHBOARD, '/settings/billing/statements'),
    domains: path(ROOTS_DASHBOARD, '/settings/domains'),
    dropshipping: path(ROOTS_DASHBOARD, '/settings/dropshipping')
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/store'),
    manage: path(ROOTS_DASHBOARD, '/store/manage'),
    pages: path(ROOTS_DASHBOARD, '/store/pages'),
    page: (id) => path(PATH_DASHBOARD.store.pages, `/${id}`),
    pageCreate: path(ROOTS_DASHBOARD, '/store/pages/create'),
    links: path(ROOTS_DASHBOARD, '/store/links'),
    linkCreate: path(ROOTS_DASHBOARD, '/store/links/create'),
    link: (id) => path(PATH_DASHBOARD.store.links, `/${id}`)
  },
  support: {
    root: path(ROOTS_DASHBOARD, '/support')
  }
};
