export const AVAILABLE_FONTS = [
  // Approved fonts
  'Public Sans',
  'CircularStd',
  // Imported fonts
  'Biro Script',
  //  -- Google fonts
  'Raleway',
  'Raleway Italic',
  'Antonio',
  'Archivo',
  'Archivo Italic',
  'Caveat',
  'Grandstander',
  'Grandstander Italic',
  'Merienda',
  'Orbitron',
  'Overpass',
  'Overpass Italic',
  'Rosario',
  'Rosario Italic',
  'Rubik',
  'Rubik Italic',
  'Sansita Swashed',
  'Alegreya Sans',
  'Averia Sans Libre',
  'Chakra Petch',
  'Bellota',
  'Cinzel Decorative',
  'Gaegu',
  // Web Safe Fonts Below
  'Helvetica',
  'Arial',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Gill Sans',
  'Times New Roman',
  'Georgia',
  'Palatino',
  'Baskerville',
  'Andale Mono',
  'Courier',
  'Lucida Grande',
  'Monaco',
  'Bradley Hand',
  'Brush Script MT',
  'Luminari',
  'Comic Sans MS',
  'Geist, sans-serif'
].sort();
