import { styled } from '@material-ui/core/styles';
import {
  Stack,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import { useState } from 'react';
import { useSnackbar } from 'notistack5';
import { HexColorPicker } from 'react-colorful';
import { Icon } from '@iconify/react';
import copyOutline from '@iconify/icons-eva/copy-outline';
import blockIcon from '@iconify/icons-akar-icons/block';
import { defaultTo, isEmpty } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';

const DEFAULT_COLOUR = '#000';
const ColourPickerBox = styled(Stack)(({ theme }) => ({
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(2)
}));

const ColourWrapper = styled(Stack)(() => ({
  alignItems: 'center',
  width: '100%'
}));

const ColourDisplay = styled(Box)(({ theme }) => ({
  width: 35,
  height: 35,
  borderRadius: theme.shape.borderRadiusCircle,
  border: `solid 1px ${theme.palette.divider}`
}));

const ChooseButton = styled(Button)(() => ({
  width: '100%',
  height: 35
}));

export const ChooseColourDialog = ({ value, open, onClose, handleChangeColour, handleClearColour }) => {
  const [colour, setColour] = useState(value);
  return (
    <Dialog fullWidth open={open} onClose={onClose} scroll="body">
      <DialogTitle sx={{ pb: 2 }}>Choose Colour</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <TextField fullWidth label="Hex value" value={colour} onChange={(e) => setColour(e.target.value)} />
          <Box sx={{ '& .react-colorful': { width: '100%' } }}>
            <HexColorPicker
              {...(colour && { color: colour })}
              onChange={(newValue) => {
                if (newValue) {
                  setColour(newValue);
                }
              }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
          <Button
            onClick={() => {
              onClose();
              handleClearColour();
            }}
          >
            Clear
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              onClose();
              handleChangeColour(colour);
            }}
          >
            Okay
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const EmptyColourDisplay = ({ sx }) => (
  <ColourDisplay sx={{ bgcolor: '#fff', border: 'none', color: (theme) => theme.palette.grey[400], ...sx }}>
    <Icon icon={blockIcon} width="100%" height="100%" color="inherit" />
  </ColourDisplay>
);

export const ColourDisplayView = ({ value, sx }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    {isEmpty(value) ? <EmptyColourDisplay sx={sx} /> : <ColourDisplay sx={{ bgcolor: value, ...sx }} />}
  </Box>
);

const ColourPicker = ({ title, value, handleChangeColour, handleClearColour, sx }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const openColourDialog = () => setOpen(true);

  const onCopy = () => {
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  return (
    <>
      <ChooseColourDialog
        open={open}
        value={defaultTo(value, DEFAULT_COLOUR)}
        onClose={() => setOpen(false)}
        handleChangeColour={handleChangeColour}
        handleClearColour={handleClearColour}
      />
      <ColourPickerBox direction="column" spacing={2} sx={sx}>
        {title && <Typography variant="subtitle2">{title} Colour</Typography>}
        <ColourWrapper direction="row" spacing={2}>
          <ColourDisplayView value={value} />
          <ColourWrapper direction="row" spacing={1}>
            <ChooseButton fullWidth variant="outlined" size="small" onClick={openColourDialog}>
              Choose Colour
            </ChooseButton>
            <CopyToClipboard text={value} onCopy={onCopy}>
              <Tooltip title="Copy">
                <IconButton>
                  <Icon icon={copyOutline} width={24} height={24} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </ColourWrapper>
        </ColourWrapper>
      </ColourPickerBox>
    </>
  );
};

export default ColourPicker;
