import { styled } from '@material-ui/core/styles';
import { Typography, Stack, Divider, AccordionDetails, Accordion, AccordionSummary } from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 2),
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.divider}`,
    '&:before': {
      backgroundColor: 'unset'
    }
  }
}));

const AccordionSummaryStyle = styled(AccordionSummary)(() => ({
  padding: 0
}));

const AccordionDetailsStyle = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

export const AccordionWrapper = ({ title, children, ...other }) => {
  return (
    <AccordionStyle defaultExpanded={false} key={title} {...other}>
      <AccordionSummaryStyle expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} color="inherit" />}>
        <Typography variant="body2">
          <strong>{title}</strong>
        </Typography>
      </AccordionSummaryStyle>
      <AccordionDetailsStyle>
        <Stack spacing={2} divider={<Divider />}>
          {children}
        </Stack>
      </AccordionDetailsStyle>
    </AccordionStyle>
  );
};
