import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import initial from './initial';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    bookingsIsLoading: false,
    definitionsIsLoading: false,
    locationsIsLoading: false,
    servicesIsLoading: false,
    bookingIsLoading: false,
    definitionIsLoading: false,
    locationIsLoading: false,
    scheduleIsLoading: false
  },
  error: {
    bookingsError: null,
    definitionsError: null,
    locationsError: null,
    servicesError: null,
    bookingError: null,
    definitionError: null,
    locationError: null,
    scheduleError: null
  },
  bookings: initial.pagableData,
  definitions: initial.pagableData,
  locations: initial.pagableData,
  services: initial.pagableData,
  booking: null,
  definition: null,
  location: null,
  schedule: null
};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    // START LOADING
    startBookingsLoading(state) {
      state.loading.bookingsIsLoading = true;
    },
    startBookingLocationsLoading(state) {
      state.loading.locationsIsLoading = true;
    },
    startBookingDefinitionsLoading(state) {
      state.loading.definitionsIsLoading = true;
    },
    startBookingServicesLoading(state) {
      state.loading.servicesIsLoading = true;
    },
    startBookingLoading(state) {
      state.loading.bookingIsLoading = true;
    },
    startBookingDefinitionLoading(state) {
      state.loading.definitionIsLoading = true;
    },
    startBookingLocationLoading(state) {
      state.loading.locationIsLoading = true;
    },
    startBookingScheduleLoading(state) {
      state.loading.scheduleIsLoading = true;
    },

    // HAS ERROR
    hasBookingsError(state, action) {
      state.loading.bookingsIsLoading = false;
      state.error.bookingsError = action.payload;
    },
    hasBookingLocationsError(state, action) {
      state.loading.locationsIsLoading = false;
      state.error.locationsError = action.payload;
    },
    hasBookingDefinitionsError(state, action) {
      state.loading.definitionsIsLoading = false;
      state.error.definitionsError = action.payload;
    },
    hasBookingServicesError(state, action) {
      state.loading.servicesIsLoading = false;
      state.error.servicesError = action.payload;
    },
    hasBookingError(state, action) {
      state.loading.bookingIsLoading = false;
      state.error.bookingError = action.payload;
    },
    hasBookingDefinitionError(state, action) {
      state.loading.definitionIsLoading = false;
      state.error.definitionError = action.payload;
    },
    hasBookingLocationError(state, action) {
      state.loading.locationIsLoading = false;
      state.error.locationError = action.payload;
    },
    hasBookingScheduleError(state, action) {
      state.loading.scheduleIsLoading = false;
      state.error.scheduleError = action.payload;
    },

    // GET DATA
    getBookingsSuccess(state, action) {
      state.loading.bookingsIsLoading = false;
      state.error.bookingsError = null;
      state.bookings = action.payload;
    },
    getBookingLocationsSuccess(state, action) {
      state.loading.locationsIsLoading = false;
      state.error.locationsError = null;
      state.locations = action.payload;
    },
    getBookingDefinitionsSuccess(state, action) {
      state.loading.definitionsIsLoading = false;
      state.error.definitionsError = null;
      state.definitions = action.payload;
    },
    getBookingServicesSuccess(state, action) {
      state.loading.servicesIsLoading = false;
      state.error.servicesError = null;
      state.services = action.payload;
    },
    getBookingSuccess(state, action) {
      state.loading.bookingIsLoading = false;
      state.error.bookingError = null;
      state.booking = action.payload;
    },
    getBookingDefinitionSuccess(state, action) {
      state.loading.definitionIsLoading = false;
      state.error.definitionError = null;
      state.definition = action.payload;
    },
    getBookingLocationSuccess(state, action) {
      state.loading.locationIsLoading = false;
      state.error.locationError = null;
      state.location = action.payload;
    },
    getBookingScheduleSuccess(state, action) {
      state.loading.scheduleIsLoading = false;
      state.error.scheduleError = null;
      state.schedule = action.payload;
    },

    // RESET
    resetBookings(state) {
      state.loading.bookingsIsLoading = false;
      state.bookings = initial.pagableData;
    },
    resetBookingLocations(state) {
      state.loading.locationsIsLoading = false;
      state.locations = initial.pagableData;
    },
    resetBookingDefinitions(state) {
      state.loading.definitionsIsLoading = false;
      state.definitions = initial.pagableData;
    },
    resetBookingServices(state) {
      state.loading.servicesIsLoading = false;
      state.services = initial.pagableData;
    },
    resetBooking(state) {
      state.loading.bookingIsLoading = false;
      state.booking = null;
    },
    resetBookingDefinition(state) {
      state.loading.definitionIsLoading = false;
      state.definition = null;
    },
    resetBookingLocation(state) {
      state.loading.locationIsLoading = false;
      state.location = null;
    },
    resetBookingSchedule(state) {
      state.loading.scheduleIsLoading = false;
      state.schedule = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  resetBookings,
  resetBooking,
  resetBookingDefinitions,
  resetBookingDefinition,
  resetBookingLocations,
  resetBookingLocation,
  resetBookingServices,
  resetBookingSchedule
} = slice.actions;

// ----------------------------------------------------------------------

// Bookings

export function getBookings(page, rowsPerPage, orderBy, order, filter) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingsLoading());
    try {
      const response = await axios.get('/manage/bookings', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filter
        }
      });
      dispatch(slice.actions.getBookingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasBookingsError(error));
    }
  };
}

export function getBooking(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingLoading());
    try {
      const response = await axios.get(`/manage/bookings/${id}`);
      dispatch(slice.actions.getBookingSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasBookingError(error));
    }
  };
}

export async function cancelBooking(bookingId) {
  return axios.put(`/manage/bookings/${bookingId}/cancel`);
}

// Booking Definitions

export const getBookingDefinitionOptions = async (page, rowsPerPage, orderBy, order, filter) => {
  const response = await axios.get('/manage/booking-definitions', {
    params: {
      page,
      size: rowsPerPage,
      orderBy,
      order,
      search: filter
    }
  });
  return response.data;
};

export function getBookingDefinitions(page, rowsPerPage, orderBy, order, filter) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingDefinitionsLoading());
    try {
      const response = await axios.get('/manage/booking-definitions', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filter
        }
      });
      dispatch(slice.actions.getBookingDefinitionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasBookingDefinitionsError(error));
    }
  };
}

export function getBookingDefinition(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingDefinitionLoading());
    try {
      const response = await axios.get(`/manage/booking-definitions/${id}`);
      dispatch(slice.actions.getBookingDefinitionSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasBookingDefinitionError(error));
    }
  };
}

export async function getBookingDefinitionByUuid(uuid) {
  return axios.get(`/manage/booking-definitions/uuid/${uuid}`);
}

export async function createBookingDefinition(values) {
  return axios.post('/manage/booking-definitions', values);
}

export async function updateBookingDefinition(id, values) {
  return axios.put(`/manage/booking-definitions/${id}`, values);
}

export function deleteBookingDefinition(id) {
  return axios.delete(`/manage/booking-definitions/${id}`);
}

export function deleteBookingDefinitions(ids) {
  return axios.post(`/manage/booking-definitions/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}

// Locations

export function getBookingLocations(bookingId, page, rowsPerPage, orderBy, order) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingLocationsLoading());
    try {
      const response = await axios.get(`/manage/booking-definitions/${bookingId}/locations`, {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order
        }
      });
      dispatch(slice.actions.getBookingLocationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasBookingLocationsError(error));
    }
  };
}

export function getBookingLocation(bookingId, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingLocationLoading());
    try {
      const response = await axios.get(`/manage/booking-definitions/${bookingId}/locations/${id}`);
      dispatch(slice.actions.getBookingLocationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasBookingLocationError(error));
    }
  };
}

export async function createBookingLocation(bookingId, values) {
  return axios.post(`/manage/booking-definitions/${bookingId}/locations`, values);
}

export async function updateBookingLocation(bookingId, id, values) {
  return axios.put(`/manage/booking-definitions/${bookingId}/locations/${id}`, values);
}

export function deleteBookingLocation(bookingId, id) {
  return axios.delete(`/manage/booking-definitions/${bookingId}/locations/${id}`);
}

// Booking Services

export function getBookingServices(bookingId, locationId, page, rowsPerPage, orderBy, order) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingServicesLoading());
    try {
      const response = await axios.get(`/manage/booking-definitions/${bookingId}/locations/${locationId}/items`, {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order
        }
      });
      dispatch(slice.actions.getBookingServicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasBookingServicesError(error));
    }
  };
}

export async function createBookingService(bookingId, locationId, values) {
  return axios.post(`/manage/booking-definitions/${bookingId}/locations/${locationId}/items`, values);
}

export async function updateBookingService(bookingId, locationId, id, values) {
  return axios.put(`/manage/booking-definitions/${bookingId}/locations/${locationId}/items/${id}`, values);
}

export function deleteBookingService(bookingId, locationId, id) {
  return axios.delete(`/manage/booking-definitions/${bookingId}/locations/${locationId}/items/${id}`);
}

// Booking Schedule

export function getBookingSchedule(bookingId, locationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startBookingScheduleLoading());
    try {
      const response = await axios.get(`/manage/booking-definitions/${bookingId}/locations/${locationId}/schedules`);
      dispatch(slice.actions.getBookingScheduleSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasBookingScheduleError(error));
    }
  };
}

export async function createBookingSchedule(bookingId, locationId, values) {
  return axios.post(`/manage/booking-definitions/${bookingId}/locations/${locationId}/schedules`, values);
}

export async function updateBookingSchedule(bookingId, locationId, values) {
  return axios.put(`/manage/booking-definitions/${bookingId}/locations/${locationId}/schedules`, values);
}

export function deleteBookingSchedule(bookingId, locationId) {
  return axios.delete(`/manage/booking-definitions/${bookingId}/locations/${locationId}/schedules`);
}
