import { styled } from '@material-ui/core/styles';
import { Stack, Slider, Typography } from '@material-ui/core';
import { AccordionWrapper } from '../Accordian';

const SLIDER_MIN_SPACING = 0;
const SLIDER_MAX_SPACING = 30;

const SpacingRootStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const ComponentSpacingPicker = ({ title, value, handleOnChange }) => {
  return (
    <AccordionWrapper title={title} sx={{ p: 0 }}>
      <SpacingRootStyle spacing={3}>
        <Stack>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Top
          </Typography>
          <Stack direction="row">
            <Typography variant="body2">{SLIDER_MIN_SPACING}</Typography>
            <Slider
              valueLabelDisplay="auto"
              value={value?.top}
              onChange={(_, val) => handleOnChange({ ...value, top: val })}
              step={1}
              marks
              min={SLIDER_MIN_SPACING}
              max={SLIDER_MAX_SPACING}
              sx={{ ml: 2, mr: 2 }}
            />
            <Typography variant="body2">{SLIDER_MAX_SPACING}</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Bottom
          </Typography>
          <Stack direction="row">
            <Typography variant="body2">{SLIDER_MIN_SPACING}</Typography>
            <Slider
              valueLabelDisplay="auto"
              value={value?.bottom}
              onChange={(_, val) => handleOnChange({ ...value, bottom: val })}
              step={1}
              marks
              min={SLIDER_MIN_SPACING}
              max={SLIDER_MAX_SPACING}
              sx={{ ml: 2, mr: 2 }}
            />
            <Typography variant="body2">{SLIDER_MAX_SPACING}</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Left
          </Typography>
          <Stack direction="row">
            <Typography variant="body2">{SLIDER_MIN_SPACING}</Typography>
            <Slider
              valueLabelDisplay="auto"
              value={value?.left}
              onChange={(_, val) => handleOnChange({ ...value, left: val })}
              step={1}
              marks
              min={SLIDER_MIN_SPACING}
              max={SLIDER_MAX_SPACING}
              sx={{ ml: 2, mr: 2 }}
            />
            <Typography variant="body2">{SLIDER_MAX_SPACING}</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Right
          </Typography>
          <Stack direction="row">
            <Typography variant="body2">{SLIDER_MIN_SPACING}</Typography>
            <Slider
              valueLabelDisplay="auto"
              value={value?.right}
              onChange={(_, val) => handleOnChange({ ...value, right: val })}
              step={1}
              marks
              min={SLIDER_MIN_SPACING}
              max={SLIDER_MAX_SPACING}
              sx={{ ml: 2, mr: 2 }}
            />
            <Typography variant="body2">{SLIDER_MAX_SPACING}</Typography>
          </Stack>
        </Stack>
      </SpacingRootStyle>
    </AccordionWrapper>
  );
};

export default ComponentSpacingPicker;
