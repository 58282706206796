import {
  Typography,
  Stack,
  Divider,
  TextField,
  FormControlLabel,
  FormHelperText,
  Link,
  Switch,
  Collapse
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { SOCIAL_OPTIONS } from 'src/utils/socialOptions';
import { SelectNavigationList } from '../../../components/SelectNavigationList';
import { SelectSocials } from '../../../components/SelectSocials';

export default function FooterEditorCommon({ form, options }) {
  const { values, errors, touched, setFieldValue, getFieldProps } = form;

  const handleNavigationSelected = (option) => {
    setFieldValue('navigationLinkIds', option);
  };

  return (
    <>
      <Divider />

      <TextField
        fullWidth
        autoComplete="off"
        label="Title"
        {...getFieldProps('title')}
        error={Boolean(touched.title && errors.title)}
        helperText={touched.title && errors.title}
      />

      <TextField
        fullWidth
        multiline
        rows={3}
        autoComplete="off"
        label="Description"
        {...getFieldProps('description')}
        error={Boolean(touched.description && errors.description)}
        helperText={touched.description && errors.description}
      />

      <div>
        <TextField
          fullWidth
          autoComplete="off"
          label="Additional Notes"
          {...getFieldProps('additionalNotes')}
          error={Boolean(touched.additionalNotes && errors.additionalNotes)}
          helperText={touched.additionalNotes && errors.additionalNotes}
        />
        <FormHelperText>This will be displayed at the bottom of the Footer</FormHelperText>
      </div>

      <Divider />

      <Typography variant="body1">
        <strong>Options</strong>
        <FormHelperText>
          These below values can be managed in your&nbsp;
          <Link to={PATH_DASHBOARD.setting.general} color="primary" target="_blank" component={RouterLink}>
            account
          </Link>
        </FormHelperText>
      </Typography>

      <Stack spacing={0}>
        <div>
          <FormControlLabel
            label="Enable Footer"
            control={<Switch checked={values.enabled} {...getFieldProps('enabled')} />}
          />
          {touched.enabled && errors.enabled && (
            <FormHelperText error>{touched.enabled && errors.enabled}</FormHelperText>
          )}
        </div>
        <Collapse in={values.enabled}>
          <Stack spacing={0}>
            <div>
              <FormControlLabel
                label="Show Logo"
                control={<Switch checked={values.showLogo} {...getFieldProps('showLogo')} />}
              />
              {touched.showLogo && errors.showLogo && (
                <FormHelperText error>{touched.showLogo && errors.showLogo}</FormHelperText>
              )}
            </div>

            <div>
              <FormControlLabel
                label="Show Store Name"
                control={<Switch checked={values.showStoreNameFooter} {...getFieldProps('showStoreNameFooter')} />}
              />
              {touched.showStoreNameFooter && errors.showStoreNameFooter && (
                <FormHelperText error>{touched.showStoreNameFooter && errors.showStoreNameFooter}</FormHelperText>
              )}
            </div>

            <div>
              <FormControlLabel
                label="Show Store Address"
                control={<Switch checked={values.showAddress} {...getFieldProps('showAddress')} />}
              />
              {touched.showAddress && errors.showAddress && (
                <FormHelperText error>{touched.showAddress && errors.showAddress}</FormHelperText>
              )}
              <Collapse in={values.showAddress} sx={{ ...(values.showAddress && { my: (theme) => theme.spacing(1) }) }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Address Header"
                  {...getFieldProps('addressHeadingText')}
                  error={Boolean(touched.addressHeadingText && errors.addressHeadingText)}
                  helperText={touched.addressHeadingText && errors.addressHeadingText}
                />
              </Collapse>
            </div>

            <div>
              <FormControlLabel
                label="Show Store Email"
                control={<Switch checked={values.showEmail} {...getFieldProps('showEmail')} />}
              />
              {touched.showEmail && errors.showEmail && (
                <FormHelperText error>{touched.showEmail && errors.showEmail}</FormHelperText>
              )}
              <Collapse in={values.showEmail} sx={{ ...(values.showEmail && { my: (theme) => theme.spacing(1) }) }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Email Header"
                  {...getFieldProps('emailHeadingText')}
                  error={Boolean(touched.emailHeadingText && errors.emailHeadingText)}
                  helperText={touched.emailHeadingText && errors.emailHeadingText}
                />
              </Collapse>
            </div>

            <div>
              <FormControlLabel
                label="Show Store Registration"
                control={<Switch checked={values.showRegistrationNo} {...getFieldProps('showRegistrationNo')} />}
              />
              {touched.showRegistrationNo && errors.showRegistrationNo && (
                <FormHelperText error>{touched.showRegistrationNo && errors.showRegistrationNo}</FormHelperText>
              )}
              <Collapse
                in={values.showRegistrationNo}
                sx={{ ...(values.showRegistrationNo && { my: (theme) => theme.spacing(1) }) }}
              >
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Registration Header"
                  {...getFieldProps('registrationHeadingText')}
                  error={Boolean(touched.registrationHeadingText && errors.registrationHeadingText)}
                  helperText={touched.registrationHeadingText && errors.registrationHeadingText}
                />
              </Collapse>
            </div>

            <div>
              <FormControlLabel
                label="Show Accepted Payment Methods"
                control={<Switch checked={values.showPaymentMethods} {...getFieldProps('showPaymentMethods')} />}
              />
              {touched.showPaymentMethods && errors.showPaymentMethods && (
                <FormHelperText error>{touched.showPaymentMethods && errors.showPaymentMethods}</FormHelperText>
              )}
            </div>

            <div>
              <FormControlLabel
                label="Enable Navigation"
                control={<Switch checked={values.enableNavigation} {...getFieldProps('enableNavigation')} />}
              />
              {touched.enableNavigation && errors.enableNavigation && (
                <FormHelperText error>{touched.enableNavigation && errors.enableNavigation}</FormHelperText>
              )}
            </div>

            <Collapse in={values.enableNavigation}>
              <div>
                <FormControlLabel
                  label="Show Navigation Titles"
                  control={<Switch checked={values.showTitle} {...getFieldProps('showTitle')} />}
                />
                <FormHelperText>This is the title associated with the navigation items in your footer</FormHelperText>
                {touched.showTitle && errors.showTitle && (
                  <FormHelperText error>{touched.showTitle && errors.showTitle}</FormHelperText>
                )}
              </div>
            </Collapse>

            <Collapse in={values.enableNavigation}>
              <Stack spacing={3} sx={{ mt: 2 }}>
                <Divider />

                <Stack spacing={2}>
                  <Typography variant="body1">
                    <strong>Navigation Links</strong>
                  </Typography>
                  <SelectNavigationList
                    values={values.navigationLinkIds}
                    options={options}
                    handleNavigationSelected={handleNavigationSelected}
                  />
                  <FormHelperText>
                    You can create a navigation link&nbsp;
                    <Link to={PATH_DASHBOARD.store.links} color="primary" component={RouterLink}>
                      here
                    </Link>
                  </FormHelperText>
                  {errors.navigationLinkIds && <FormHelperText error>{errors.navigationLinkIds}</FormHelperText>}
                </Stack>
              </Stack>
            </Collapse>
          </Stack>
        </Collapse>
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Social Channels</strong>
        </Typography>
        <SelectSocials
          values={values.socialLinks}
          options={SOCIAL_OPTIONS}
          handleSave={(newValues) => setFieldValue('socialLinks', newValues)}
        />
      </Stack>
    </>
  );
}
