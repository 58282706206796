import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Typography, Stack, Button } from '@material-ui/core';
import { defaultTo } from 'lodash';
import FooterOrientationEditorCommon from './FooterOrientationEditorCommon';
import { EditorConatiner } from '../../../common/EditorConatiner';
import { REQUIRED_OPTION_MESSAGE } from '../../../utils/constants';
import {
  DEFAULT_SPACING_DESKTOP,
  DEFAULT_SPACING_MOBILE,
  GENERIC_CONTENT_POSITIONS,
  LAYOUT_NAVIGATION_ITEM_VIEW
} from '../../../utils/constantsEditor';

export default function FooterOrientationEditor({ heading, config, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const textPosition = config?.textPosition;
  const mobileTextPosition = config?.mobileTextPosition;
  const mobileNavigationItemView = config?.mobileNavigationItemView;
  const navigationPosition = config?.navigationPosition;
  const switchDesktopOrientation = config?.switchDesktopOrientation;
  const container = config?.container;
  const desktopSpacing = config?.desktopSpacing;
  const mobileSpacing = config?.mobileSpacing;

  const FooterSchema = Yup.object().shape({
    textPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    mobileTextPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    mobileNavigationItemView: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    navigationPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    switchDesktopOrientation: Yup.boolean().required(REQUIRED_OPTION_MESSAGE)
  });

  const form = useFormik({
    initialValues: {
      textPosition: defaultTo(textPosition, GENERIC_CONTENT_POSITIONS[1].value),
      mobileTextPosition: defaultTo(mobileTextPosition, GENERIC_CONTENT_POSITIONS[1].value),
      mobileNavigationItemView: defaultTo(mobileNavigationItemView, LAYOUT_NAVIGATION_ITEM_VIEW[0].value),
      navigationPosition: defaultTo(navigationPosition, GENERIC_CONTENT_POSITIONS[1].value),
      switchDesktopOrientation: defaultTo(switchDesktopOrientation, true),
      // Container
      container: defaultTo(container, false),
      // Spacing
      desktopSpacing: defaultTo(desktopSpacing, DEFAULT_SPACING_DESKTOP),
      mobileSpacing: defaultTo(mobileSpacing, DEFAULT_SPACING_MOBILE)
    },
    validationSchema: FooterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent({
          ...config,
          ...values
        });
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <FooterOrientationEditorCommon form={form} />
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
